import Vue from 'vue';
import {toDate, format, parseISO} from 'date-fns';

let altIconString = "";
const vm = new Vue();


export default {

  /*
      beforeCreate() {
        log = this.$log.debug; //make logger available to support methods here
      },
  */
  methods: {

    /**
     * @param pub
     * @return {*}
     */
    displayDate: function (pub) {
      vm.$log.debug(`{displayDate} pub:`, pub, {});
      let dObj = toDate(parseISO(pub));
      return format(dObj, 'do MMMM yyyy'); //NB Unicode technical Standard 35 formats!
    },


    /** TODO not needed I think on website
     * extractBaseDomain - extracts a base domain from a full domain from document.location.host
     *
     * @param domainIn - e.g. one.untangl.co.uk
     * @return {*|string|*} - e.g. untangl.co.uk
     * @private
     */
    extractBaseDomain: function (domainIn) {
      const domainRXone = /^one\.(\w+(?:\.\w+)*)$/;
      let r             = domainIn.match(domainRXone);
      vm.$log.debug(`{_extractBaseDomain} match gives`, r, {});
      if (r == null) {
        return domainIn; //cannot detect any subdomain
      }
      else {
        if (r.length === 2) {
          return r[1]; //core domain
        }
        else {
          return domainIn; //don't know what it is so play safe
        }
      }
    }

  }

}

